import Connect from '../../../Connect/Connect';

const windmillRun = async (path: string, params: any) => {
  const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
  const options = {
    method: 'POST',
    headers: new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf
    }),
    body: JSON.stringify(params)
  };
  const response = await fetch(path, options);
  const data = await response.json();
  return JSON.parse(data?.['body']?.['response']);
};

export const getZuoraWorkdayMappings = async (
  path: string,
  auth: {
    WDB_SERVICE_URL: string;
    WDB_USERNAME: string;
    WDB_PASSWORD: string;
    WDB_ZR_CLIENT_ID: string;
    WDB_ZR_CLIENT_SECRET: string;
    WDB_ZR_REST_ENDPOINT: string;
  },
  type: string = 'summary'
) => {
  const result = { workdayFields: [], zuoraFields: [] };
  try {
    const response = await windmillRun(path, {
      'script_path': 'f/workday_for_billing/flows',
      'params': {
        'action': 'get_zr_wd_mappings',
        'auth': auth,
        'type': type
      },
      'async': false
    });
    result.workdayFields = response['workdayFields'];
    result.zuoraFields = response['zuoraFields'];
  } catch (error) {
    Connect.log(error);
  }
  return result;
};

export const validateZuoraWorkday = async (
  path: string,
  auth: {
    WDB_SERVICE_URL: string;
    WDB_USERNAME: string;
    WDB_PASSWORD: string;
    WDB_ZR_CLIENT_ID: string;
    WDB_ZR_CLIENT_SECRET: string;
    WDB_ZR_REST_ENDPOINT: string;
  }
) => {
  const result = {
    workday: {
      isValid: false,
      status: 200,
      statusText: ''
    },
    zuora: {
      isValid: false,
      status: 200,
      statusText: ''
    }
  };
  try {
    const response = await windmillRun(path, {
      'script_path': 'f/workday_for_billing/flows',
      'params': {
        'action': 'validate_zr_wd',
        'auth': auth
      },
      'async': false
    });
    result.workday = response['workday'];
    result.zuora = response['zuora'];
  } catch (error) {
    Connect.log(error);
  }
  return result;
}

export const buildWorkdayBillingGlobalConstants = (auth: {
  WDB_SERVICE_URL: string;
  WDB_USERNAME: string;
  WDB_PASSWORD: string;
  WDB_ZR_CLIENT_ID: string;
  WDB_ZR_CLIENT_SECRET: string;
}, emailNotifications: {
  enabled: boolean;
  failures: boolean;
  success: boolean;
  emails: string[];
}, fieldMappings: any, envRestEndpoint: string) => {
  // build field mappings
  let mappings = {};
  const reportType = fieldMappings['reportType'] || 'summary';
  const mappingKeys = Object.keys(fieldMappings);
  if (mappingKeys.length > 0) {
    for (let key of mappingKeys) {
      if (key.startsWith('z-')) {
        const rawMappings = fieldMappings[key]?.[reportType]?.['mappings'] || {};
        const rawMappingKeys = Object.keys(rawMappings);
        if (rawMappingKeys.length > 0) {
          for (let rmKey of rawMappingKeys) {
            const mapping = rawMappings[rmKey];
            const key = mapping['source']['text'];
            const value = mapping['target']['text'];
            mappings[key] = value;
          }
        }
      }
    }
  }

  // build email configs
  let emailConfig = {};
  if (emailNotifications.enabled) {
    emailConfig = {
      'Failures': emailNotifications.failures || false,
      'Success': emailNotifications.success || false,
      'Emails': emailNotifications.emails || []
    };
  }

  return {
    "WDB_SERVICE_URL": auth.WDB_SERVICE_URL,
    "WDB_USERNAME": auth.WDB_USERNAME,
    "WDB_PASSWORD": auth.WDB_PASSWORD,
    "WDB_ZR_CLIENT_ID": auth.WDB_ZR_CLIENT_ID,
    "WDB_ZR_CLIENT_SECRET": auth.WDB_ZR_CLIENT_SECRET,
    "WDB_ZR_REST_ENDPOINT": envRestEndpoint,
    "WDB_MAPPINGS": JSON.stringify(mappings),
    "WDB_EMAIL_CONFIG": JSON.stringify(emailConfig),
    "WDB_ACTION_TYPE": reportType
  };
};